@import "~firebaseui/dist/firebaseui.css";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.gm-style-iw {
  max-width: 350px !important;
  background-color: rgba(64, 64, 64, .75);
  box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
  border-radius: 5px;
  padding: 0 !important;
}

.occupancy-map-header {
  background-color: #ff8d00;
  padding: 1px 0;
}

.occupancy-map-header h5 {
  color: #eee;
  padding: 0 10px;
}

.occupancy-map-content {
  background-color: #b4b4b4;
}

.occupancy-map-payment-info {
  background-color: #cecece;
  width: 100%;
}

.occupancy-map-key {
  width: 130px;
  padding: 2px 7px;
  text-align: right;
}

.occupancy-map-value {
  line-height: 1.5em;
  padding: 2px 7px;
}

.occupancy-map-group-label {
  background-color: rgb(57, 119, 167);
  border-radius: 3px;
  color: white;
  margin-right: 3px;
  padding: 2px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
}


/* LinearProgress bar overrides for payment loading in info window */
#payment-linear-loading-bar > .MuiLinearProgress-colorPrimary {
  background-color: rgb(182, 188, 226);
}

#payment-linear-loading-bar > .MuiLinearProgress-barColorPrimary {
  background-color: rgb(255, 110, 0) !important;
}

#payment-linear-loading-bar > .MuiLinearProgress-bar1Indeterminate {
  width: auto;
  animation: MuiLinearProgress-keyframes-indeterminate1 1.0s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

#payment-linear-loading-bar > .MuiLinearProgress-bar2Indeterminate {
  width: auto;
  animation: MuiLinearProgress-keyframes-indeterminate2 1.0s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
}
/* End of LinearProgress bar overrides */

#payment-validation-error {
  color: red;
  margin: 2px 0;
  width: 100%;
  text-align: center;
}

.occupied {
  color: rgb(199, 24, 62);
  font-weight: 600;
}

.vacant {
  color: rgb(34, 156, 81);
  font-weight: 600;
}

.overstay {
  color: rgb(180, 20, 214);
  font-weight: 600;
}

.low {
  color: rgb(255, 0, 0);
  font-weight: 600;
}

.moderate {
  color: rgb(255, 110, 0);
  font-weight: 600;
}

.high {
  color: rgb(34, 156, 81);
  font-weight: 600;
}

.unknown {
  color: rgb(57, 119, 167);
  font-weight: 600;
}